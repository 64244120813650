/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql, Link } from "gatsby"
import Obfuscate from "react-obfuscate"
import { FaFacebook, FaInstagram, FaYoutube, FaLinkedin, FaPhone } from "react-icons/fa"

import Header from "./header"
import "./all.sass"

const Layout = ({ children, lang, location }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <>
      <Header
        siteTitle={data.site.siteMetadata.title}
        lang={lang}
        location={location}
      />
      <div className="container is-fullhd">
        <main>{children}</main>
        <footer>
          <div className="columns has-text-centered">
            <div className="column is-uppercase has-text-weight-bold is-size-7">
              <div className="mb-4 mt-4">
                <Link to={`/${lang}/contact/`}>Contact</Link>
              </div>
              <div>
                <Link to={`/${lang}/mentions/`}>
                  {lang === "en" ? "Legal notices" : "Mentions légales"}
                </Link>
              </div>
            </div>
            <div className="column is-uppercase">
              <div>
                <div>
                  {lang === "en" ? "Follow me on:" : "Me retrouver sur :"}
                </div>
                <div className="is-size-2 mt-2">
                  {location && location.pathname.indexOf('/animation_musicale/') > -1 ? (
                    <>
                      <a href="https://www.facebook.com/Lacuzon-loisirs-Animations-musicales-1475772565929431" className="mr-4">
                        <FaFacebook />
                      </a>
                      <a href="https://www.instagram.com/lacuzonloisirs_animations/" className="mr-4" style={{color: "#cd0061"}} title="Lacuzon Loisirs Animations">
                        <FaInstagram />
                      </a>
                      <a href="https://www.youtube.com/channel/UCgb3rRdkKAHzYsxtMWR4Bww" className="mr-4" style={{color: "#e52d27"}} title="Lacuzon Loisirs Animations">
                        <FaYoutube />
                      </a>
                    </>
                  ) : (location && location.pathname.indexOf('/gite/') > -1 ? (
                    <>
                      <a href="https://www.facebook.com/G%C3%AEte-GUYOT-Jura-101060245025335" className="mr-4">
                        <FaFacebook />
                      </a>
                      <a href="https://www.instagram.com/gite_guyot_jura/" className="mr-4" style={{color: "#cd0061"}} title="Gite Guyot Jura">
                        <FaInstagram />
                      </a>
                      <a href="https://www.youtube.com/channel/UCj4cyfYUU_KyFanBKlRUlIw" className="mr-4" style={{color: "#e52d27"}} title="Gite Guyot Jura">
                        <FaYoutube />
                      </a>
                    </>
                  ) : (
                    <>
                      <a href="https://www.facebook.com/Lacuzon-loisirs-682095778873104/" className="mr-4">
                        <FaFacebook />
                      </a>
                      <a href="https://www.instagram.com/lacuzonloisirs_excursions/" className="mr-4" style={{color: "#cd0061"}} title="Lacuzon Loisirs Excursions">
                        <FaInstagram />
                      </a>
                      <a href="https://www.youtube.com/channel/UCr5mHpqWHmJkzyVuzZP7PkQ" className="mr-4" style={{color: "#e52d27"}} title="Lacuzon Loisirs Excursions">
                        <FaYoutube />
                      </a>
                    </>
                  ))}
                  <a href="https://www.linkedin.com/in/ludovic-guyot-0708448a/?originalSubdomain=fr" style={{color: "#0976b4"}}>
                    <FaLinkedin />
                  </a>
                </div>
              </div>
            </div>
            <div className="column is-size-7">
              <div>Ludovic GUYOT</div>
              <div>Lacuzon loisirs</div>
              <div style={{ color: "#4a4343", fontSize: 30 }}>
                <FaPhone /> 06 88 59 92 29
              </div>
              <div>
                <Obfuscate email="contact@lacuzonloisirs.fr" />
              </div>
            </div>
          </div>
        </footer>
      </div>
    </>
  )
}

Layout.defaultProps = {
  lang: `fr`,
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  lang: PropTypes.string,
  location: PropTypes.object,
}

export default Layout
