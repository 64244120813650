import { useStaticQuery, graphql, Link } from "gatsby"
import PropTypes from "prop-types"
import React, { useState } from "react"
import Img from "gatsby-image"

const trans = {
  home: { en: "Home", fr: "Accueil" },
  about: { en: "Who am i?", fr: "Qui suis-je ?" },
  rando: { en: "Excursions and outing", fr: "Excursions et randonnées" },
  music: { en: "Musical animation", fr: "Animation musicale" },
  partners: { en: "Partners", fr: "Partenaires" },
  gite: {en: "Guyot lodging", fr: "Gîte Guyot"},
}

const Header = ({ siteTitle, lang, location }) => {
  const [isActive, setIsActive] = useState(false)
  const data = useStaticQuery(graphql`
    query {
      logo: file(relativePath: { eq: "lacuzon.png" }) {
        childImageSharp {
          fixed(width: 167, height: 90) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      fr: file(relativePath: { eq: "france.png" }) {
        childImageSharp {
          fixed(width: 30, height: 30) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      gb: file(relativePath: { eq: "gb.png" }) {
        childImageSharp {
          fixed(width: 30, height: 30) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `)
  return (
    <header className="container is-fullhd">
      <nav className="navbar" role="navigation" aria-label="main navigation">
        <div className="navbar-brand">
          <Link to={`/${lang}/`}>
            <Img
              fixed={data.logo.childImageSharp.fixed}
              alt={siteTitle}
              objectFit="cover"
              objectPosition="50% 50%"
            />
          </Link>

          <div
            className={`navbar-burger burger`}
            aria-label="menu"
            aria-expanded="false"
            data-target="main-menu"
            onClick={() => setIsActive(!isActive)}
            onKeyPress={() => {}}
            tabIndex={0}
            role="button"
          >
            <span aria-hidden="true"></span>
            <span aria-hidden="true"></span>
            <span aria-hidden="true"></span>
          </div>
        </div>

        <div
          id="main-menu"
          className={`navbar-menu ${isActive ? "is-active" : ""}`}
        >
          <div className="navbar-start">
            <Link to={`/${lang}/`} className="navbar-item">
              {trans.home[lang]}
            </Link>
            <Link to={`/${lang}/qui-suis-je/`} className="navbar-item">
              {trans.about[lang]}
            </Link>
            <Link
              to={`/${lang}/accompagnateur-de-tourisme/`}
              className="navbar-item"
            >
              {trans.rando[lang]}
            </Link>
            <Link to={`/${lang}/animation_musicale/`} className="navbar-item">
              {trans.music[lang]}
            </Link>
            <Link to={`/${lang}/gite/`} className="navbar-item">
              {trans.gite[lang]}
            </Link>
            <Link to={`/${lang}/partners/`} className="navbar-item">
              {trans.partners[lang]}
            </Link>
            <Link to={`/${lang}/agenda/`} className="navbar-item">
              Agenda
            </Link>
          </div>
          <div className="navbar-end">
            <Link
              to={location ? location.pathname.replace(/\/en/, "/fr") : '/'}
              className="navbar-item pl-1 pr-1"
            >
              <Img fixed={data.fr.childImageSharp.fixed} alt="Français" />
            </Link>
            <Link
              to={location ? (location.pathname !== '/' ? location.pathname.replace(/\/fr/, "/en") : '/en/') : '/en/'}
              className="navbar-item pl-1 pr-1"
            >
              <Img fixed={data.gb.childImageSharp.fixed} alt="English" />
            </Link>
          </div>
        </div>
      </nav>
    </header>
  )
}

Header.propTypes = {
  lang: PropTypes.string,
  location: PropTypes.object.isRequired,
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  lang: `fr`,
  siteTitle: ``,
}

export default Header
